@-webkit-keyframes control_effect {
  0% {
    -webkit-transform: translate(0);
  }

  25% {
    -webkit-transform: translateY(20%);
  }

  75% {
    -webkit-transform: translateY(-20%);
  }

  to {
    -webkit-transform: translate(0);
  }
}

.home {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.home .swiper-wrapper {
  width: 100%;
  height: 100%;
}

.home .swiper-wrapper .dbg {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}

.home .swiper-wrapper .dbg .download_img {
  width: 2rem;
  position: absolute;
  bottom: 21%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.home .swiper-wrapper .dbg .next_common {
  width: 0.18rem;
  position: absolute;
  left: 50%;
  bottom: 16%;
  margin-left: -0.1rem;
  -webkit-animation: control_effect 1.4s linear infinite both;
}

.home .swiper-wrapper .dbg .next_common .next_img {
  width: 100%;
  vertical-align: top;
}