.home {
  /* 手机 */
}

.home .swipe_box {
  width: 100%;
  height: 4rem;
}

.home .swipe_box img {
  width: 100%;
  height: 4rem;
}

@media all and (max-width: 768px) {
  .home .swipe_box {
    width: 100% !important;
    height: 1.2rem !important;
  }

  .home .swipe_box img {
    height: 1.2rem !important;
  }

  .home .footer {
    width: 100% !important;
  }
}

.home .footer {
  margin-top: 0.5rem;
  display: flex;
  color: #FFC107;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding-left: 0.2rem;
}

.home .footer h4 {
  text-align: left;
}

.home .footer .logos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.1rem 0;
}

.home .footer .logos img {
  width: 0.4rem;
  margin-right: 0.1rem;
}