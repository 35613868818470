.score-detail /deep/ .mint-header.is-fixed {
  top: 0.68rem !important;
}

.score-detail .top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0.5rem;
}

.score-detail .info {
  padding: 0.68rem 0.1rem 0.1rem 0.1rem;
  color: #fff;
}

.score-detail .info .tops {
  margin-bottom: 0.2rem;
}

.score-detail .info .item {
  word-break: break-word;
}

.score-detail .info .item h3 {
  font-size: 0.2rem;
}

.score-detail .info .item p {
  margin-bottom: 0.05rem;
}