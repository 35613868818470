.page-box {
  display: flex;
  width: 100%;
}

.box-list {
  width: 70%;
  display: flex;
  position: relative;
}

.box-calendar {
  width: 30%;
  margin-left: 1.9%;
}

.bg-content {
  width: 90%;
  height: 100%;
  position: absolute;
  top: 2%;
  right: 0;
  background-color: #ffffff;
  opacity: 0.4;
}

.u-txt-span {
  width: 100%;
  padding: 0.04rem 0;
  text-align: center;
  font-family: 'PingFangSC-Regular';
  font-size: 0.14rem;
  color: #171717;
  /* background-color: #f3f3f3; */
}

.u-border-top {
  border-top: 0.01rem solid #dcdcdc;
}

.u-border {
  width: 100%;
  height: 4%;
  background-color: #ffffff;
  border-bottom: 0.01rem solid #dcdcdc;
}

.border-box-ul {
  width: 90%;
  position: relative;
  top: 2%;
}

/* 已排计划 */

.time-bg-yp {
  background: rgba(224, 32, 32, 0.2);
}

/* 空闲 */

.time-bg-kx {
  background: rgba(0, 181, 50, 0.2);
}