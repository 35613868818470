button {
  border: 0;
  background-color: transparent;
  outline: none;
}

.page-content {
  width: 100%;
  margin-left: 0.02rem;
  margin-bottom: 0.94rem;
}

.title {
  width: 100%;
  display: flex;
  margin: 2.1% 0 1.6% 0;
}

.thead {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.t-btn {
  width: 24%;
  display: flex;
}

.btn-span {
  width: 0.64rem;
  height: 0.32rem;
  font-family: 'PingFangSC-Medium';
  font-size: 0.14rem;
  color: #575757;
  background-color: #ffffff;
  border: 0.01rem solid #dcdcdc;
  border-radius: 0.03rem 0 0 0.03rem;
}

.thead-date {
  /* width: 40%; */
  font-family: 'PingFangSC-Medium';
  font-weight: bold;
  font-size: 0.24rem;
  color: #171717;
  text-align: center;
}

.icon-img {
  /* width: 20%; */
  background-size: 0.24rem 0.24rem;
}

.icon-right {
  margin-left: 0.24rem;
  text-align: right;
}

.icon-left {
  margin-right: 0.24rem;
  text-align: left;
}

.active {
  color: #016ae0;
  border: 0.01rem solid #006ae1;
}

.box-content {
  display: flex;
  align-items: center;
  margin-bottom: 2.4%;
}

.plan-txt {
  font-family: 'PingFangSC-Regular';
  font-size: 0.14rem;
  color: #171717;
  margin-left: 0.3%;
}

/* 已安排 */

.already {
  color: #e02020;
}

/* 空闲 */

.free {
  color: #00b532;
}

/* 未排计划 */

.not-already {
  color: #8c8c8c;
}

/* 已安排 */

.already-radius {
  /* content: ''; */
  width: 0.08rem;
  height: 0.08rem;
  border: 0.01rem solid #e02020;
  background-color: #e02020;
  border-radius: 50% 50%;
}

/* 空闲 */

.free-radius {
  /* content: ''; */
  width: 0.08rem;
  height: 0.08rem;
  border: 0.01rem solid #00b532;
  background-color: #00b532;
  border-radius: 50% 50%;
  margin-left: 1.6%;
}

/* 未排计划 */

.not-already-radius {
  /* content: ''; */
  width: 0.08rem;
  height: 0.08rem;
  border: 0.01rem solid #8c8c8c;
  background-color: #8c8c8c;
  border-radius: 50% 50%;
  margin-left: 1.6%;
}

.box-table {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.box-w25 {
  width: 25%;
  display: flex\0;
  padding: 0 0.06rem;
  margin-bottom: 2.9%;
}

.box-td {
  cursor: pointer;
  display: flex;
  width: 100%;
}

.tab-cursor {
  cursor: pointer;
}