.GuessYouLike {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.15rem 0.1rem;
}

.GuessYouLike .top {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.1rem;
}

.GuessYouLike .top .title {
  color: #ffd602;
}

.GuessYouLike .top .more {
  border: 0.01rem solid;
  border-color: #ffd602;
  color: #ffd602;
  border-radius: 0.05rem;
  padding: 0.05rem 0.06rem;
  font-size: 0.15rem;
}

.GuessYouLike .box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.GuessYouLike .box .items {
  width: 49%;
  border-radius: 0 0 0.05rem 0.05rem;
  margin-bottom: 0.1rem;
}

.GuessYouLike .box .items .top_bg {
  border-left: 0.01rem solid;
  border-right: 0.01rem solid;
  border-top: 0.01rem solid;
  border-color: #ffd602;
  border-radius: 0.05rem 0.05rem 0 0;
  padding: 0.05rem;
}

.GuessYouLike .box .items .top_bg img {
  width: 100%;
}

.GuessYouLike .box .items .center {
  border-left: 0.01rem solid;
  border-right: 0.01rem solid;
  border-color: #ffd602;
  background-color: #593c22;
  color: #fff;
  display: flex;
  font-size: 0.12rem;
  /* justify-content: start; */
  padding-left: 0.15rem;
  text-align: left;
}

.GuessYouLike .box .items .bottom {
  background-color: #bcbcbc;
  display: flex;
  padding: 0.1rem 0.05rem;
  border-radius: 0 0 0.05rem 0.05rem;
  position: relative;
  justify-content: space-between;
}

.GuessYouLike .box .items .bottom .time {
  padding: 0.02rem 0.05rem;
  border-radius: 0.15rem;
  color: #fff;
  background-color: #58595a;
  text-align: center !important;
  position: absolute;
  right: 0.14rem;
  bottom: 0.2rem;
  font-size: 0.11rem;
}

.GuessYouLike .box .items .bottom .code {
  font-size: 0.12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.GuessYouLike .box .items .bottom .ii {
  font-size: 0.11rem;
}

.GuessYouLike .box .items .bottom .ii p {
  text-align: left;
}

.GuessYouLike .box .items .bottom .ii .vs {
  color: red;
  font-size: 0.1rem;
  font-weight: bold;
  text-align: center !important;
}

.GuessYouLike .box .item {
  width: calc(50% - 0.1rem);
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
  display: flex;
  flex-direction: column;
}

.GuessYouLike .box .item img {
  width: 100%;
  height: 1rem;
  border-radius: 0.1rem;
}

.GuessYouLike .box .item span {
  font-size: 0.16rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}