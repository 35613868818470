.History {
  width: 100%;
  padding-top: 0.4rem;
  height: calc(100% - 0.2rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.History .page-part {
  font-size: 0.12rem;
}