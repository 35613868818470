.mine::before {
  content: "";
  display: block;
  width: 93%;
  margin: 0 auto;
  height: 6rem;
  background: #ffd602;
  position: fixed;
  left: 50%;
  top: 0;
  border-radius: 50%;
  transform: translate(-50%, -75%);
}

/* 手机 */

@media all and (max-width: 768px) {
  .mine::before {
    width: 175% !important;
  }
}

.mine {
  width: 100%;
  height: 100%;
}

.mine .content {
  position: relative;
  z-index: 1;
  padding-top: 0.75rem;
  width: 90%;
  margin: 0 auto;
}

.mine .content .panel-login {
  height: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mine .content .panel-login .avatar {
  width: 0.76rem;
  height: 0.76rem;
  border-radius: 0.1rem;
}

.mine .content .panel-login .avatar .iconicon30 {
  font-size: 0.76rem;
}

.mine .content .panel-login .font {
  font-size: 0.16rem;
}

.mine .content .panel-login .ood {
  color: orange;
  margin-top: 0.05rem;
}

.mine .content .panel-login .panel-bottom {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  top: 1.8rem;
}

.mine .content .panel-login .panel-bottom .a1 {
  display: flex;
  flex-direction: column;
}

.mine .content .panel-login .panel-bottom .a1 p:first-child {
  font-weight: bold;
  font-size: 0.2rem;
}

.mine .content .panel-login .panel-bottom .a1 p:last-child {
  font-size: 0.15rem;
  color: #000;
  opacity: 0.7;
}

.mine .content .panel {
  box-shadow: 0 0.13rem 0.26rem 0 #ffd602;
  border-radius: 0.1rem;
  margin-bottom: 0.25rem;
  background: #fff;
  font-size: 0.16rem;
}

.mine .content .level_box {
  background-color: #ffd602;
  border-radius: 0.1rem;
  padding: 0.05rem 0.1rem;
}

.mine .content .level_box .top_box {
  display: flex;
  flex-direction: row;
  font-size: 0.15rem;
  align-items: center;
}

.mine .content .level_box .top_box .left {
  width: calc(100% - 1rem);
}

.mine .content .level_box .top_box .left .top {
  font-size: 0.13rem;
  display: flex;
  justify-content: space-between;
}

.mine .content .level_box .top_box .left .top span:first-child {
  color: orange;
}

.mine .content .level_box .top_box .right {
  width: 0.8rem;
  margin-left: 0.15rem;
  margin-top: 0.15rem;
}

.mine .content .level_box .top_box .right button {
  width: 0.85rem;
  height: 0.3rem;
  background-color: #fff;
  border: none;
  border-radius: 0.2rem;
}

.mine .content .level_box p {
  font-size: 0.13rem;
  text-align: left;
}

.mine .content .level_box p span i {
  color: orange;
  margin: 0 0.05rem;
}

.mine .content .list {
  margin-top: 0.25rem;
  border-radius: 0.1rem;
  box-shadow: -0.02rem 0.03rem 0.03rem 0.03rem #f2f2f2;
  background-color: #fff;
}

.mine .content .list .item {
  display: flex;
  padding: 0.15rem;
  align-items: center;
  border-bottom: 0.01rem solid #f2f2f2;
}

.mine .content .list .item .iconfont {
  width: 0.3rem;
  font-size: 0.2rem;
  margin-right: 0.1rem;
}

.mine .content .list .item span {
  font-size: 0.16rem;
}

.mine .content .list .item:last-child {
  border: none;
}