#content {
  width: 100%;
  height: 100%;
  padding-bottom: 0.47rem;
}

#content #box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.2rem;
}

#content .own {
  width: 0.2rem;
  margin: 0 auto;
  color: #ffc107;
}