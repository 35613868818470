.Collect {
  width: 100%;
  padding-top: 0.4rem;
  height: calc(100% - 0.41rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.Collect .bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #eee;
  border-top: 0.01rem solid #ddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Collect .bottom div {
  width: 50%;
  padding: 0.1rem 0;
  font-size: 0.15rem;
}

.Collect .bottom div:first-child {
  width: calc(50% - 0.01rem) !important;
  border-right: 0.01rem solid #ddd;
}