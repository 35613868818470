.tutorial-index img {
  width: 100%;
  height: auto;
  vertical-align: top;
}

.tutorial-index .button {
  background: #5980f3;
  padding: 1.333vw 0 10.667vw 0;
}

.tutorial-index .button .back {
  width: 80vw;
  margin: 0 auto;
  padding: 2.667vw 0;
  text-align: center;
  font-size: 4vw;
  color: #5d4ca7;
  background: #fff;
  border-radius: 10.667vw;
}