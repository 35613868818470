.home .ios-index {
  width: 100%;
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-color: #4d81fb;
  background-size: 100%;
}

.home .ios-index .box {
  width: 92vw;
  padding: 60vw 0 13.333vw 0;
  margin: 0 auto;
}

.home .ios-index .box .item {
  width: 92vw;
  padding: 0 0 4vw 0;
  background: #fff;
  border-radius: 2.667vw;
  position: relative;
  margin: 0 0 4vw 0;
}

.home .ios-index .box .item .setup {
  width: 20vw;
  height: 10.667vw;
  line-height: 9.067vw;
  position: absolute;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 20vw 10.667vw;
  left: -2.4vw;
  top: 3.467vw;
  color: #fff;
  text-align: center;
}

.home .ios-index .box .item .body {
  padding: 16vw 0 0 0;
}

.home .ios-index .box .item .body .text p {
  text-align: center;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 1;
  font-size: 4.267vw;
  font-weight: 700;
  color: #333;
}

.home .ios-index .box .item .body .text p:last-child {
  color: #666;
  font-size: 3.733vw;
  padding: 2.667vw 0 0 0;
  font-weight: 400;
}

.home .ios-index .box .item .body .button a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 53.333vw;
  height: 12vw;
  margin: 6.133vw auto 0 auto;
  border-radius: 12vw;
  border: 0.01rem solid #4d81fb;
  text-decoration: none;
}

.home .ios-index .box .item .body .button .ico {
  width: 8vw;
  height: 8vw;
  margin: 0 2.667vw 0 0;
}

.home .ios-index .box .item .body .button .ico img {
  width: 100%;
  vertical-align: top;
}

.home .ios-index .box .item .body .button .tip {
  color: #333;
  font-size: 4vw;
  font-weight: 700;
}

.home .ios-index .box .item .body .tutorial {
  text-align: center;
  color: red;
  padding: 2.667vw 0 1.333vw 0;
  margin: 2.667vw 0 1.333vw 0;
}

.home .ios-index .box .item .body .tutorial .text {
  font-size: 3.733vw;
}