.Play {
  padding-top: 0.4rem;
  height: calc(100% - 0.2rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.Play /deep/ .mint-header.is-fixed {
  top: 0.68rem !important;
}

.Play .bodys {
  /* 手机 */
}

.Play .bodys .fullscreen {
  display: flex;
  position: fixed;
  top: 25%;
  left: -1.69rem;
  width: calc(100vh - 0.6rem) !important;
  height: 100vw !important;
  transform: rotate(90deg);
  z-index: 99;
}

.Play .bodys .fullscreen .close_ {
  font-size: 0.15rem;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #000;
  width: 0.3rem;
  justify-content: center;
}

.Play .bodys .fullscreen .close_ .ii {
  transform: rotate(90deg);
}

.Play .bodys .showBody {
  padding: 0.05rem 0.1rem;
}

.Play .bodys .tabs {
  display: flex;
  color: #fff;
  font-size: 0.14rem;
  background-color: #171717;
}

.Play .bodys .tabs .sel {
  color: #ffd602;
  border-bottom: 0.02rem solid #ffd602;
}

.Play .bodys .tabs .noSel {
  color: #fff;
  border: none !important;
}

.Play .bodys .tabs p {
  padding: 0.1rem 0.12rem;
}

.Play .bodys .only {
  height: 6.45rem !important;
}

.Play .bodys .video {
  width: 100%;
  height: 4rem;
}

@media all and (max-width: 768px) {
  .Play .bodys .video {
    height: 2rem !important;
  }

  .Play .bodys .only {
    height: 4.45rem !important;
  }
}

.Play .center {
  padding: 0.2rem;
  padding-bottom: 0;
}

.Play .center .ad_box {
  background-color: #fbe802;
  border-radius: 0.1rem;
  display: flex;
  flex-direction: row;
  padding: 0.1rem;
  justify-content: space-between;
}

.Play .center .ad_box div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 0.14rem;
}

.Play .center .ad_box .iconplume {
  color: #fff;
  font-size: 0.3rem;
}

.Play .center .center_ss {
  display: flex;
  flex-direction: column;
  margin: 0.1rem 0;
}

.Play .center .center_ss p {
  font-size: 0.2rem;
  padding: 0.05rem 0;
  text-align: left;
}

.Play .center .center_ss .bottom_ss {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Play .center .center_ss .bottom_ss .left {
  color: #607d8b;
  font-size: 0.17rem;
}

.Play .center .center_ss .bottom_ss .right {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.16rem;
}

.Play .center .center_ss .bottom_ss .right .iconjiarushoucang {
  font-size: 0.22rem;
}

.Play .center .tag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Play .center .tag p {
  padding: 0.05rem 0.1rem;
  background-color: #f7efe4;
  color: #997e55;
  font-size: 0.14rem;
  border-radius: 0.15rem;
  margin-right: 0.1rem;
}

.Play .bottom_title {
  width: 0.8rem;
  text-align: left;
  font-size: 0.18rem;
  border-bottom: 0.03rem solid #fbe802;
  margin-left: 0.15rem;
}