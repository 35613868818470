#cnzz_stat_icon_1279854591 {
  display: none;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  font-family: "Fahkwang";
  background-image: url('./assets/comBg.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

/* 手机 */

@media all and (max-width: 768px) {
  #app {
    width: 100% !important;
  }
}

button {
  outline: none;
}

ul,
li {
  list-style: none;
  -webkit-padding-start: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
  width: 80%;
  margin: 0 auto;
  position: relative;
  padding-top: 0.68rem;
}

#app {
  text-align: center;
  color: #2c3e50;
  font-size: 0.2rem;
}

#nav {
  padding: 0.3rem;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.bg {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 3;
  top: 0;
}

.bg .download_box {
  position: relative;
}

.bg .download_box img {
  position: absolute;
  width: 3.12rem;
  height: 0.86rem;
  bottom: 0.79rem;
}

.bg .download_box .left {
  left: 0.2rem;
}

.bg .download_box .right {
  right: 0.2rem;
}

.bg img {
  width: 100%;
  height: 100%;
}

.bg #time {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #9e9e9e;
  opacity: 0.9;
  color: white;
  z-index: 2;
  border-radius: 50%;
  position: absolute;
  top: 0.23rem;
  right: 0.15rem;
  padding-top: 0.12rem;
}

.bon {
  font-size: 0.2rem;
  background-color: #9e9e9e;
  opacity: 0.9;
  color: white;
  position: absolute;
  top: 0.23rem;
  right: 0.15rem;
  width: 0.5rem;
  height: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}