.el-menu {
  border: none !important;
}

.el-menu .el-menu-item {
  width: 0.8rem !important;
  padding: 0 0.05rem !important;
  text-alight: center !important;
}

.el-menu .is-active {
  border-bottom: 0.01rem solid #ffd602 !important;
}

.el-menu--popup-bottom-start {
  background-color: #1c1b1a !important;
}

.el-menu--popup-bottom-start .el-menu-item {
  background-color: #1c1b1a !important;
  width: 100% !important;
  text-align: center;
}

.el-radio-button__inner {
  color: #ffc107 !important;
  background-color: #000 !important;
  border-color: #ffc107 !important;
}

.is-active .el-radio-button__inner {
  color: #000 !important;
  background-color: #ffd602 !important;
  border-color: #ffd602 !important;
  font-weight: bold;
  box-shadow: -0.01rem 0 0 0 #ffd602 !important;
}

.el-table {
  background-color: #000 !important;
}

.el-table th.el-table__cell {
  background-color: transparent !important;
}

.el-table tr {
  background-color: transparent !important;
  color: #ffc107 !important;
}

.el-table td.el-table__cell {
  background-color: transparent !important;
}

.el-table--border:after,
.el-table--group:after,
.el-table:before {
  background-color: #ffc107 !important;
}

.el-table--border,
.el-table--group {
  border-color: #ffc107 !important;
}

.el-table td,
.el-table th.is-leaf {
  border-bottom: 0.01rem solid #ffc107 !important;
}

.el-table--border th,
.el-table--border th.gutter:last-of-type {
  border-bottom: 0.01rem solid #ffc107 !important;
}

.el-table--border td,
.el-table--border th {
  border-right: 0.01rem solid #ffc107 !important;
}