.TypeList {
  width: 100%;
  padding-top: 0.4rem;
  height: calc(100% - 0.2rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.TypeList .navbar {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.TypeList .navbar .item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.14rem;
  padding: 0.05rem 0.1rem;
  width: 0.8rem;
  flex: none;
}

.TypeList .navbar .active {
  border-bottom: 0.02rem solid #fbe802 !important;
  color: #fbe802 !important;
}

.TypeList .page-part {
  font-size: 0.12rem;
}