.Recommend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.15rem 0.1rem;
}

.Recommend .title {
  margin-bottom: 0.1rem;
  height: 0.28rem;
  text-align: left;
}

.Recommend .title p {
  color: #ffd602;
}

.Recommend .box {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  width: 100%;
  /* 手机 */
}

@media all and (max-width: 768px) {
  .Recommend .box .items {
    width: 1.3rem !important;
  }

  .Recommend .box .items .top .left span {
    font-size: 0.1rem !important;
  }

  .Recommend .box .items .top .right {
    font-size: 0.1rem !important;
  }

  .Recommend .box .items .team {
    font-size: 0.13rem !important;
  }
}

.Recommend .box .items {
  display: flex;
  flex-direction: column;
  width: 2rem;
  height: 1.2rem;
  padding: 0.1rem;
  border-radius: 0.1rem;
  background-color: #242526;
  margin-right: 0.1rem;
  position: relative;
}

.Recommend .box .items .live {
  background-color: #dd0404;
  color: #fff;
  position: absolute;
  left: 0.1rem;
  top: 0.3rem;
  border-radius: 0.05rem;
  padding: 0.03rem;
  font-size: 0.1rem;
}

.Recommend .box .items .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a7aaae;
}

.Recommend .box .items .top .left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Recommend .box .items .top .left img {
  width: 0.2rem;
}

.Recommend .box .items .top .left span {
  font-size: 0.13rem;
}

.Recommend .box .items .top .right {
  font-size: 0.13rem;
}

.Recommend .box .items .team {
  padding-top: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
  font-size: 0.15rem;
}

.Recommend .box .items .team p {
  margin-bottom: 0.1rem;
}

.Recommend .box .item {
  flex-shrink: 0;
  width: 1.5rem;
  margin-right: 0.1rem;
  display: flex;
  flex-direction: column;
}

.Recommend .box .item img {
  width: 100%;
  height: 1rem;
  border-radius: 0.1rem;
}

.Recommend .box .item span {
  font-size: 0.16rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}