.vdList {
  width: 100%;
}

.vdList .box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* 手机 */
}

@media all and (max-width: 768px) {
  .vdList .box .items {
    width: 49% !important;
  }
}

.vdList .box .items {
  width: 3.5rem;
  border-radius: 0 0 0.05rem 0.05rem;
  margin-bottom: 0.1rem;
  cursor: pointer;
  /* 手机 */
}

@media all and (max-width: 768px) {
  .vdList .box .items .top_bg {
    height: auto !important;
  }

  .vdList .box .items .top_bg img {
    width: 100% !important;
    height: 2rem !important;
  }

  .vdList .box .items .time {
    padding: 0.1rem 0.05rem !important;
  }

  .vdList .box .items .center {
    font-size: 0.12rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .vdList .box .items .center p {
    font-size: 0.18rem !important;
  }

  .vdList .box .items .bottom {
    height: 0.6rem !important;
  }

  .vdList .box .items .bottom .content {
    font-size: 0.14rem !important;
  }

  .vdList .box .items .bottom .time {
    right: 0.14rem !important;
    bottom: 0.05rem !important;
    font-size: 0.11rem !important;
  }

  .vdList .box .items .bottom .code {
    font-size: 0.12rem !important;
  }

  .vdList .box .items .bottom .ii {
    font-size: 0.11rem !important;
  }

  .vdList .box .items .bottom .ii .vs {
    font-size: 0.1rem !important;
  }
}

.vdList .box .items .top_bg {
  background-color: #000;
  height: 3.15rem;
  position: relative;
  border-left: 0.01rem solid;
  border-right: 0.01rem solid;
  border-top: 0.01rem solid;
  border-width: 0.05rem;
  border-color: #403600;
  border-style: solid;
  transition: opacity 0.2s ease;
  opacity: 1;
  border-radius: 0.05rem 0.05rem 0 0;
}

.vdList .box .items .top_bg .live {
  background-color: #dd0404;
  color: #fff;
  position: absolute;
  left: 0.1rem;
  top: 0.1rem;
  border-radius: 0.05rem;
  padding: 0.03rem;
  font-size: 0.1rem;
}

.vdList .box .items .top_bg img {
  width: 100%;
  height: 3.15rem;
  border-radius: 0.05rem 0.05rem 0 0;
}

.vdList .box .items .time {
  border-left: 0.01rem solid;
  border-right: 0.01rem solid;
  border-width: 0.05rem;
  border-color: #403600;
  transition: opacity 0.2s ease;
  opacity: 1;
  color: #FFC107;
  text-align: left;
  padding: 0.25rem 0.1rem;
  font-size: 0.16rem;
  padding-bottom: 0;
}

.vdList .box .items .center {
  padding-top: 0.1rem;
  border-left: 0.01rem solid;
  border-right: 0.01rem solid;
  border-width: 0.05rem;
  border-color: #403600;
  transition: opacity 0.2s ease;
  opacity: 1;
  color: #FFC107;
  display: flex;
  font-size: 0.22rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.vdList .box .items .center p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 0.55rem;
  font-size: 0.2rem;
  font-weight: bold;
  text-align: left;
}

.vdList .box .items .bottom {
  display: flex;
  padding: 0.05rem 0.1rem;
  padding-top: 0.1rem;
  height: 0.8rem;
  border-radius: 0 0 0.05rem 0.05rem;
  position: relative;
  border-left: 0.01rem solid;
  border-right: 0.01rem solid;
  border-bottom: 0.01rem solid;
  border-width: 0.05rem;
  border-color: #403600;
  transition: opacity 0.2s ease;
  opacity: 1;
  color: #FFC107;
  justify-content: space-between;
}

.vdList .box .items .bottom .content {
  font-size: 0.14rem;
  overflow: hidden;
  height: 0.4rem;
  width: 100%;
  text-align: left;
}

.vdList .box .items .bottom .dd {
  line-height: 0.6rem;
}

.vdList .box .items .bottom .time {
  padding: 0.02rem 0.05rem;
  border-radius: 0.15rem;
  color: #fff;
  background-color: #58595a;
  text-align: center !important;
  position: absolute;
  right: 0.22rem;
  bottom: 0.1rem;
  font-size: 0.14rem;
}

.vdList .box .items .bottom .code {
  font-size: 0.15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vdList .box .items .bottom .ii {
  font-size: 0.15rem;
}

.vdList .box .items .bottom .ii p {
  text-align: left;
}

.vdList .box .items .bottom .ii .vs {
  color: red;
  font-size: 0.14rem;
  font-weight: bold;
  text-align: center !important;
}

.vdList .box .item {
  width: calc(50% - 0.1rem);
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
  display: flex;
  flex-direction: column;
}

.vdList .box .item img {
  width: 100%;
  height: 1rem;
  border-radius: 0.1rem;
}

.vdList .box .item span {
  font-size: 0.16rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}