.Fullscreen {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.Fullscreen .trans {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.6;
  z-index: 9;
}

.Fullscreen .normal {
  width: 100% !important;
  height: 100% !important;
}

.Fullscreen .no_normal {
  transform: rotate(90deg);
}

.Fullscreen .box .close_ {
  font-size: 0.15rem;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #000;
  width: 0.3rem;
  justify-content: center;
}

.Fullscreen .box .close_ .ii {
  transform: rotate(90deg);
}