.vdList {
  width: 100%;
}

.vdList .box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* 手机 */
}

@media all and (max-width: 768px) {
  .vdList .box .items {
    width: 49% !important;
  }
}

.vdList .box .items {
  width: 2.6rem;
  border-radius: 0 0 0.05rem 0.05rem;
  margin-bottom: 0.1rem;
  /* 手机 */
}

@media all and (max-width: 768px) {
  .vdList .box .items .top_bg {
    height: auto !important;
  }

  .vdList .box .items .top_bg img {
    width: 100% !important;
  }

  .vdList .box .items .center {
    font-size: 0.12rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .vdList .box .items .bottom .time {
    right: 0.14rem !important;
    bottom: 0.2rem !important;
    font-size: 0.11rem !important;
  }

  .vdList .box .items .bottom .code {
    font-size: 0.12rem !important;
  }

  .vdList .box .items .bottom .ii {
    font-size: 0.11rem !important;
  }

  .vdList .box .items .bottom .ii .vs {
    font-size: 0.1rem !important;
  }
}

.vdList .box .items .top_bg {
  background-color: #000;
  height: 2.15rem;
  position: relative;
  border-left: 0.01rem solid;
  border-right: 0.01rem solid;
  border-top: 0.01rem solid;
  border-color: #ffd602;
  border-radius: 0.05rem 0.05rem 0 0;
}

.vdList .box .items .top_bg .live {
  background-color: #dd0404;
  color: #fff;
  position: absolute;
  left: 0.1rem;
  top: 0.1rem;
  border-radius: 0.05rem;
  padding: 0.03rem;
  font-size: 0.1rem;
}

.vdList .box .items .top_bg img {
  width: 66%;
  border-radius: 0.05rem 0.05rem 0 0;
}

.vdList .box .items .center {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border-left: 0.01rem solid;
  border-right: 0.01rem solid;
  border-color: #ffd602;
  background-color: #593c22;
  color: #fff;
  display: flex;
  font-size: 0.17rem;
  padding-left: 0.15rem;
}

.vdList .box .items .center p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vdList .box .items .bottom {
  background-color: #bcbcbc;
  display: flex;
  padding: 0.1rem 0.05rem;
  border-radius: 0 0 0.05rem 0.05rem;
  position: relative;
  justify-content: space-between;
}

.vdList .box .items .bottom .time {
  padding: 0.02rem 0.05rem;
  border-radius: 0.15rem;
  color: #fff;
  background-color: #58595a;
  text-align: center !important;
  position: absolute;
  right: 0.22rem;
  bottom: 0.27rem;
  font-size: 0.14rem;
}

.vdList .box .items .bottom .code {
  font-size: 0.15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vdList .box .items .bottom .ii {
  font-size: 0.15rem;
}

.vdList .box .items .bottom .ii p {
  text-align: left;
}

.vdList .box .items .bottom .ii .vs {
  color: red;
  font-size: 0.14rem;
  font-weight: bold;
  text-align: center !important;
}

.vdList .box .item {
  width: calc(50% - 0.1rem);
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
  display: flex;
  flex-direction: column;
}

.vdList .box .item img {
  width: 100%;
  height: 1rem;
  border-radius: 0.1rem;
}

.vdList .box .item span {
  font-size: 0.16rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}