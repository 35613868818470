/* 手机 */

@media all and (max-width: 768px) {
  .tips-detail .lists .item .top {
    flex-direction: column !important;
  }

  .tips-detail .lists .item .bottom {
    flex-direction: column !important;
  }
}

.tips-detail /deep/ .mint-header.is-fixed {
  top: 0.68rem !important;
}

.tips-detail .lists {
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
  color: #fff;
}

.tips-detail .lists .item {
  margin: 0.2rem auto;
  border-radius: 0.2rem;
  color: #ffd602;
  text-align: center;
  padding: 0.1rem;
  width: 80%;
}

.tips-detail .lists .item .top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.3rem;
  font-weight: bold;
}

.tips-detail .lists .item .center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-size: 0.35rem;
  font-weight: bold;
  margin: 0.2rem 0;
  border-top: 0.01rem solid #ffd602;
  padding-top: 0.1rem;
}

.tips-detail .lists .item .center img {
  width: 1rem;
}

.tips-detail .lists .item .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.35rem;
  font-weight: bold;
}

.tips-detail .lists .item .bottom .bet {
  font-size: 0.23rem;
  background-color: #ffd602;
  color: #000;
  padding: 0.05rem 0.1rem;
  width: 1rem;
  border-radius: 0.05rem;
  cursor: pointer;
}

.tips-detail .lists .item .itemTop {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tips-detail .lists .item .itemTop img {
  width: 0.5rem;
  border-radius: 0.1rem;
  margin-right: 0.05rem;
}

.tips-detail .lists .item .itemTop .team {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tips-detail .lists .item .time {
  font-size: 0.15rem;
  margin: 0.1rem 0;
}