.test-box {
  /* width: 100vw;
  height: 100vh; */
  width: 100%;
  text-align: center;
}

/* 表格边框 */

.box-border-header {
  border-top: 0.01rem solid #dcdcdc;
  border-left: 0.01rem solid #dcdcdc;
  border-right: 0;
  border-bottom: 0;
}

.box-border {
  border-bottom: 0.01rem solid #dcdcdc;
  border-right: 0.01rem solid #dcdcdc;
}

/* 月日历样式Start */

.max-w {
  width: 100%;
}

.secd {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.13rem;
}

.max-p {
  width: 14.2%;
  display: flex;
  justify-content: center;
  font-family: 'PingFangSC-Regular';
  font-size: 0.18rem;
  color: #a9a9a9;
}

.content-time {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.max-days {
  width: 14.286%;
  display: flex;
  justify-content: center;
  font-family: 'PingFangSC-Medium';
  font-size: 0.24rem;
  /* 日期天数默认颜色，未排计划 */
  color: #8c8c8c;
}

/* 已安排 */

.already {
  color: #e02020;
}

/* 空闲 */

.free {
  color: #00b532;
}

.m_30 {
  margin: 0.3rem 0 0.3rem 0;
  padding: 0.3rem\0 0\0 0.3rem\0 0\0;
}

/** End */

/* 年日历样式Start */

.min-w {
  width: 100%;
}

.box-title {
  font-family: 'PingFangSC-Regular';
  font-weight: normal;
  font-size: 0.2rem;
  color: #016ae0;
  text-align: left;
  margin-bottom: 0.12rem;
}

.min-p {
  width: 15%;
  display: flex;
  justify-content: center;
  font-family: 'PingFangSC-Regular';
  font-size: 0.12rem;
  color: #8c8c8c;
}

.min-days {
  width: 14.286%;
  display: flex;
  justify-content: center;
}

.m_12 {
  width: 0.18rem;
  height: 0.2rem;
  line-height: 0.2rem;
  font-family: 'PingFangSC-Medium';
  font-size: 0.12rem;
  font-weight: normal;
  /* 日期天数默认颜色，空闲 */
  color: #171717;
}

/** 选择的日 */

.current-day {
  color: #FFFFFF!important;
  background-color: #016ae0;
  border-radius: 50% 50%;
}