.types {
  padding: 0.1rem;
}

.types .title {
  margin-bottom: 0.1rem;
}

.types .boxs .box {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0.2rem;
  background-color: #ffc107;
  border-radius: 0.1rem;
  padding-top: 1.55rem;
  color: #fff;
  padding-bottom: 0.1rem;
  margin-bottom: 0.2rem;
  padding-left: 0.2rem;
}

.types .boxs .box .img {
  border-radius: 0.05rem 0.05rem;
  border: 0.01rem solid black;
}

.types .boxs .box p {
  display: flex;
  font-size: 0.16rem;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.types .boxs .box .tip {
  display: flex;
  font-size: 0.2rem;
  font-weight: bold;
  margin-bottom: 0.1rem;
}

.types .boxs .box .dec {
  font-size: 0.14rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
}

.types .boxs .box .tag {
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  padding-top: 0.1rem;
  color: white;
  font-size: 0.14rem;
  justify-content: flex-start;
}

.types .boxs .box .tag .tag-content {
  display: inline-block;
  padding: 0.05rem 0.1rem;
  background: #c2bdbd;
  border-radius: 1rem;
  margin-left: 0.2rem;
  font-size: 0.13rem;
  background-color: #ffbc00;
  color: #000;
}

.types .boxs .box .tag .tag-content:first-child {
  margin-left: 0;
}