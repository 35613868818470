.mint-tabbar {
  width: 81%;
  margin: 0 auto;
  background: #fff !important;
  border-top: none !important;
}

.mint-tabbar .mint-tab-item .mint-tab-item-label {
  display: flex;
  flex-direction: column;
}

.mint-tabbar .mint-tab-item .mint-tab-item-label .iconfont {
  margin-bottom: 0.05rem;
  color: #ffc107;
}

.mint-tabbar .mint-tab-item .mint-tab-item-label span {
  color: #000;
  opacity: 0.7;
  font-size: 0.15rem;
  font-weight: bold;
}

.mint-tabbar .is-selected {
  background-color: transparent !important;
}

.mint-tabbar .is-selected .iconfont {
  color: #ffd602 !important;
}

.mint-tabbar .is-selected span {
  color: #000 !important;
}

/* 手机 */

@media all and (max-width: 768px) {
  .mint-tabbar {
    width: 100% !important;
  }
}

.is-fixed {
  z-index: 2 !important;
}

.mint-button--primary {
  color: #fff !important;
  background-color: #99692f !important;
  margin-bottom: 0.2rem;
}

.mint-button--default {
  color: #000 !important;
  background-color: #fff !important;
  border: 0.01rem solid #eee;
  margin-bottom: 0.2rem;
}

.mint-header-button > a {
  text-decoration: none;
}

.mint-header {
  width: 81%;
  margin: 0 auto;
  background-color: #000 !important;
  color: #ffd602 !important;
}

.mint-header .mint-header-title {
  font-size: 0.16rem;
}

/* 手机 */

@media all and (max-width: 768px) {
  .mint-header {
    width: 100% !important;
  }
}

.mint-navbar .is-selected {
  border-bottom: 0.03rem solid #fbe802 !important;
  color: #fbe802 !important;
}

.mint-navbar .mint-tab-item .mint-tab-item-label {
  font-size: 0.15rem;
}

.mint-msgbox-confirm {
  background-color: #fbe802 !important;
  color: #000 !important;
  font-size: 0.17rem;
}

.mt-progress .mt-progress-content .mt-progress-progress {
  background-color: #00bcd4;
  border-radius: 0.1rem;
}

.mint-actionsheet .mint-actionsheet-list .mint-actionsheet-listitem {
  background: #181818;
  color: #ffd602;
  border-color: #ffd602;
}