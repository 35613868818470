.Teach {
  padding: 0.1rem;
}

.Teach .content {
  overflow: hidden;
}

.Teach .content .nextPage {
  width: 1.5rem;
  padding: 0.1rem;
  background: #ffd602;
  border-radius: 0.1rem;
  color: #000;
  font-size: 0.19rem;
  margin: 0.2rem auto;
  cursor: pointer;
}

.Teach .content ul {
  margin: 0.05rem;
}

.Teach .content ul li {
  display: flex;
  width: 100%;
  background-color: transparent;
  border: 0.01rem solid #ffd602;
  margin-bottom: 0.05rem;
  border-radius: 0.05rem;
  height: 1.8rem;
  padding-left: 0.05rem;
}

.Teach .content ul li .img {
  width: 30%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Teach .content ul li .img img {
  max-width: 100%;
  display: block;
  width: 100%;
  height: 1.2rem;
}

.Teach .content ul li .info {
  width: 70%;
  padding: 0.05rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #ffd602;
}

.Teach .content ul li .info h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Teach .content ul li .info .time {
  font-size: 0.12rem;
  color: #ffd602;
}

.Teach .content ul li .info .con {
  font-size: 0.14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 0.5rem;
  word-wrap: break-word;
}