.extend /deep/ .mint-header.is-fixed {
  top: 0.68rem !important;
}

.extend .searchDialogModal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 8;
  background-color: #000;
  opacity: 0.6;
}

.extend .searchDialog {
  position: fixed;
  width: 3rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  /* top: 100px; */
  background-color: transparent;
  z-index: 9;
}

.extend .searchDialog .btnBox {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.2rem;
}

.extend .search {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 0.4rem;
  font-size: 0.14rem;
  color: #BB822B;
  background-color: #3a3b3c;
  width: 100%;
  height: 0.5rem;
  position: relative;
}

.extend .search .disableBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}

.extend .bodys {
  padding: 0 0.1rem;
}

.extend .bodys .time {
  background-color: #4a4b4c;
  color: #fff;
  text-align: left;
  padding-left: 0.1rem;
  font-size: 0.15rem;
  padding: 0.1rem 0.05rem;
}

.extend .bodys .codes {
  background-color: #3a3b3c;
}

.extend .bodys .codes .top {
  background-color: #b77e3b;
  color: #fff;
  text-align: left;
  padding-left: 0.1rem;
  font-size: 0.14rem;
  padding: 0.1rem 0.05rem;
}

.extend .bodys .codes .code_list {
  display: flex;
  justify-content: space-between;
  padding: 0.1rem 0.05rem;
  border-bottom: 0.01rem solid #242526;
}

.extend .bodys .codes .code_list .left {
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 60%;
  font-size: 0.14rem;
  justify-content: space-around;
  border-right: 0.01rem solid #242526;
}

.extend .bodys .codes .code_list .left .ii {
  display: flex;
  justify-content: space-between;
}

.extend .bodys .codes .code_list .left .ii p:last-child {
  margin-right: 0.1rem;
}

.extend .bodys .codes .code_list .right {
  display: flex;
  width: 38%;
  flex-direction: column;
  color: #fff;
}

.extend .bodys .codes .code_list .right .time2 {
  font-size: 0.12rem;
  text-align: left;
  padding-bottom: 0.1rem;
}

.extend .bodys .codes .code_list .right .bb {
  background-color: #805a43;
  font-size: 0.14rem;
  padding: 0.05rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.extend .bodys .codes .code_list .right .bb .red_point {
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 100%;
  background-color: #ef4444;
  margin-right: 0.05rem;
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

.extend .bodys .codes .code_list .right .bb-2 {
  background-color: #4a4b4c !important;
}