.teach-detail {
  /* 手机 */
}

.teach-detail /deep/ .mint-header.is-fixed {
  top: 0.68rem !important;
}

.teach-detail .top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0.5rem;
}

.teach-detail .link_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  margin: 0 auto;
  margin-top: 0.3rem;
}

.teach-detail .link_box img {
  width: 0.3rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

@media all and (max-width: 768px) {
  .teach-detail .link_box {
    width: 90% !important;
  }

  .teach-detail .info {
    width: 90% !important;
  }
}

.teach-detail .info {
  padding: 0.68rem 0.2rem 0.2rem 0.2rem;
  color: #FFC107;
  width: 70%;
  margin: 0 auto;
  border: 0.01rem solid;
  border-radius: 0.1rem;
  border-color: rgba(64, 54, 0, 0.75);
}

.teach-detail .info .item {
  word-break: break-word;
  padding: 0.1rem 0;
}

.teach-detail .info .item h2 {
  font-size: 0.25rem;
  margin-bottom: 0.05rem;
}

.teach-detail .info .item p {
  margin-bottom: 0.05rem;
}