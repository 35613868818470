.Reback {
  padding-top: 0.4rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  width: 100%;
  height: calc(100% - 0.2rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.Reback .arrow {
  color: #fff;
}

.Reback .arrow span {
  display: inline-block;
  font-size: 0.14rem;
  left: 0;
  margin-right: 3.2rem;
  width: 100%;
  text-align: left;
}

.idea {
  width: 100%;
  margin-top: 0.1rem;
  display: flex;
  flex-wrap: wrap;
}

.idea button {
  width: 29%;
  height: 0.35rem;
  margin-top: 0.1rem;
  margin-left: 0.1rem;
  border-radius: 0.2rem;
  background: white;
  color: #000;
  border: none;
  outline: none;
}

.idea .handle {
  background-color: #ffd602;
  color: #fff;
}

.content {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.content .arrow-content {
  color: #fff;
  font-size: 0.14rem;
  margin-right: 3.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  width: 100%;
  text-align: left;
}

.submit {
  width: 80%;
  height: 0.4rem;
  margin-top: 0.2rem;
  background: #ffd602;
  border-radius: 0.2rem;
  font-size: 0.16rem;
  border: none;
  outline: none;
  color: #fff;
}