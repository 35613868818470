.game {
  width: 100%;
  height: 100%;
}

.game .top_img {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.game .top_img .hongbao {
  width: 30%;
  height: 1rem;
}

.game .top_img .ad_1 {
  width: 70%;
}

.game .tab {
  padding-top: 0.2rem;
  display: flex;
  justify-content: space-around;
}

.game .tab button {
  border-radius: 0.3rem;
  background: gold;
  color: sandybrown;
  width: 40%;
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.18rem;
  font-weight: bold;
  border: none;
}

.game .des {
  color: gold;
  font-weight: bold;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  background: #4d204d;
  width: 90%;
  margin: 0.2rem  auto;
  border-radius: 0.1rem;
}

.game .des p {
  display: flex;
  justify-content: flex-start;
  padding-top: 0.1rem;
  padding-left: 0.3rem;
  font-size: 0.16rem;
  color: #eee;
}