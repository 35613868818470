.ExtendCenter {
  padding-top: 0.4rem;
  padding-left: 0.15rem;
  padding-right: 0.15rem;
}

.ExtendCenter .information {
  background: #eee;
  border-radius: 0.1rem;
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.1rem;
}

.ExtendCenter .information .iconfont {
  font-size: 0.7rem;
}

.ExtendCenter .information .center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.ExtendCenter .information .center p {
  font-size: 0.12rem;
  display: flex;
  align-items: space-around;
}

.ExtendCenter .information .duanwei {
  display: flex;
  align-items: center;
}

.ExtendCenter .information .duanwei .iconfont {
  font-size: 0.4rem;
  display: flex;
  justify-content: space-around;
}

.ExtendCenter .level_box {
  background-color: #fbe802;
  border-radius: 0.1rem;
  padding: 0.05rem 0.1rem;
}

.ExtendCenter .level_box .top_box {
  display: flex;
  flex-direction: row;
  font-size: 0.15rem;
  align-items: center;
}

.ExtendCenter .level_box .top_box .left {
  width: calc(100% - 1rem);
}

.ExtendCenter .level_box .top_box .left .top {
  font-size: 0.13rem;
  display: flex;
  justify-content: space-between;
}

.ExtendCenter .level_box .top_box .left .top span:first-child {
  color: orange;
}

.ExtendCenter .level_box .top_box .right {
  width: 0.8rem;
  margin-left: 0.15rem;
  margin-top: 0.15rem;
}

.ExtendCenter .level_box .top_box .right button {
  width: 0.85rem;
  height: 0.3rem;
  background-color: #fff;
  border: none;
  border-radius: 0.2rem;
}

.ExtendCenter .level_box p {
  font-size: 0.13rem;
  text-align: left;
}

.ExtendCenter .level_box p span i {
  color: orange;
  margin: 0 0.05rem;
}

.level {
  font-size: 0.16rem;
  padding-top: 0.2rem;
}

ul {
  display: flex;
  justify-content: space-around;
  font-size: 0.12rem;
  padding-top: 0.2rem;
  margin-left: 0.3rem;
}

ul li:nth-child(2) {
  margin-left: 0.15rem;
}

.level_1 {
  border: 0.01rem solid #eee;
  border-radius: 0.2rem;
  height: 0.6rem;
  display: flex;
  justify-content: space-around;
  margin-top: 0.1rem;
  align-items: center;
  font-size: 0.12rem;
}

.level_1 p:nth-child(2) {
  font-size: 0.14rem;
}

.level_1 p:nth-child(3) {
  font-size: 0.14rem;
}

.level_1 .iconfont {
  font-size: 0.2rem;
  margin-top: 0.1rem;
}