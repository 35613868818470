/* 手机 */

@media all and (max-width: 768px) {
  .search {
    width: 100% !important;
  }
}

.search {
  width: 81%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 99;
  background-color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 0.65rem;
  padding-right: 0.1rem;
}

.search .logo {
  width: 0.7rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search .logo img {
  width: 100%;
  height: 100%;
}

.search .mLogo {
  margin-left: 43% !important;
}

.search .login {
  padding: 0.06rem 0.05rem;
  border-radius: 0.05rem;
  font-size: 0.14rem;
  border-color: #ffd602;
  color: #FFC107;
  margin-right: 0.2rem;
  cursor: pointer;
}

.search .right_content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search .right_lang {
  padding: 0.06rem 0.05rem;
  border-radius: 0.05rem;
  font-size: 0.14rem;
  border-color: #bb822b;
  color: #FFC107;
}

.search .right_lang .ooo {
  font-size: 0.2rem;
}

.search .right_lang .ii {
  color: #bb822b !important;
}

.search .left_img {
  display: flex;
}

.search .left_img img {
  height: 0.48rem;
  margin-right: 0.1rem;
}

.search .right {
  width: 0.4rem;
  height: 0.3rem;
}

.search .icon {
  padding: 0 0.1rem;
}

.search .iconLOGO {
  margin-right: 0.1rem;
  font-size: 0.18rem;
  width: 0.4rem;
  height: 0.35rem;
}

.search .center_box {
  position: relative;
  width: calc(100% - 0.2rem - 0.4rem - 0.4rem);
}

.search .center_box .center_input {
  border: 0.01rem solid #fff;
  border-radius: 0.1rem;
  height: 0.3rem;
  line-height: 0.3rem;
  width: 100%;
  padding-left: 0.3rem;
  background-color: #eee;
}

.search .center_box .iconsousuo {
  position: absolute;
  left: 0.08rem;
  top: 0.06rem;
}

.search .search_list {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  top: 0;
}

.search .search_list .header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0.1rem 0;
}

.search .search_list .header .iconfont {
  font-size: 0.18rem;
}

.search .search_list .header .center_box {
  position: relative;
  width: calc(100% - 0.4rem - 0.28rem - 0.4rem);
}

.search .search_list .header .center_box .center_input {
  border: 0.01rem solid #fff;
  border-radius: 0.1rem;
  height: 0.3rem;
  line-height: 0.3rem;
  width: 100%;
  padding-left: 0.3rem;
  background-color: #eee;
}

.search .search_list .header .center_box .iconsousuo {
  position: absolute;
  left: 0.08rem;
  top: 0.06rem;
}

.search .search_list .header .btn {
  font-size: 0.17rem;
}

.search .search_list .header button {
  margin-bottom: 0;
}

.search .search_list .center {
  display: flex;
  flex-direction: column;
  margin-top: 0.15rem;
}

.search .search_list .center .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.1rem;
}

.search .search_list .center .title h5 {
  font-weight: bold;
}

.search .search_list .center .title i {
  font-size: 0.19rem;
}

.search .search_list .center .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.1rem;
  padding-left: 0.1rem;
}

.search .search_list .center .content p {
  background-color: #eee;
  color: #000;
  margin-right: 0.2rem;
  font-size: 0.16rem;
  padding: 0 0.1rem;
  border-radius: 0.15rem;
  height: 0.3rem;
  line-height: 0.3rem;
  text-align: center;
  margin-top: 0.1rem;
}

.search .search_list .videos {
  -webkit-overflow-scrolling: touch;
}

.search .iconliwu1 {
  margin-left: 0.1rem;
}