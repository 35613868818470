.LoadMore .mint-loadmore-top {
  font-size: 0.16rem;
}

.LoadMore .mint-loadmore-bottom {
  font-size: 0.16rem;
}

.LoadMore .to-the-bottom {
  font-size: 0.16rem;
}