.vdListItem {
  padding: 0.05rem 0.2rem;
}

.vdListItem .content {
  background-color: #fff;
  border-radius: 0.1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.vdListItem .content .select {
  display: flex;
  align-items: center;
}

.vdListItem .content .select .iconfont {
  padding: 0.05rem;
  border-radius: 50%;
  background-color: #fbe802;
  color: #fff;
  font-size: 0.12rem;
  margin-right: 0.1rem;
}

.vdListItem .content .select .no_icon {
  border: 0.01rem solid #000;
  padding: 0.1rem;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 0.1rem;
}

.vdListItem .content img {
  width: 1.5rem;
  height: 1rem;
  border-radius: 0.1rem;
}

.vdListItem .content .defalut {
  width: calc(100% - 1.5rem);
}

.vdListItem .content .only {
  width: calc(100% - 1.5rem - 0.32rem);
}

.vdListItem .content .right {
  display: flex;
  flex-direction: column;
}

.vdListItem .content .right .sspan {
  font-size: 0.14rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.vdListItem .content .right .tag {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.vdListItem .content .right .tag p {
  padding: 0.05rem 0.1rem;
  background-color: #f7efe4;
  color: #997e55;
  font-size: 0.14rem;
  border-radius: 0.15rem;
}