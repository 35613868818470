.login {
  padding: 0.2rem;
}

.login .close {
  height: 0.3rem;
  text-align: right;
  color: #fff;
}

.login .close .iconguanbi {
  font-size: 0.25rem;
}

.login .a1 {
  text-align: left;
  color: #fff;
}

.login .a2 {
  font-size: 0.15rem;
}

.login .form {
  margin-top: 0.35rem;
  margin-bottom: 0.45rem;
}

.login .booo .ii {
  color: #fff;
  font-size: 0.14rem;
  color: #2196f3;
}

.login .bottom {
  font-size: 0.14rem;
  margin-top: 0.8rem;
  color: #2196f3;
}