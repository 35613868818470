.NoData {
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  justify-content: center;
  align-items: center;
}

.NoData img {
  width: 2rem;
  height: 2rem;
}

.NoData p {
  font-size: 0.16rem;
  color: #ffd602;
}